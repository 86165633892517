<!--
  PACKAGE_NAME : src/pages/ewm/hr/work/schedule/popup
  FILE_NAME : modal-page-attendance-log.vue
  AUTHOR : devyoon91
  DATE : 2024-06-18
  DESCRIPTION : 
-->
<template>
  <div class="container">
    <DxPopup
      :show-title="true"
      :title="attendanceLog.label"
      :min-width="attendanceLog.minWidth"
      :width="attendanceLog.width"
      :min-height="attendanceLog.minHeight"
      :height="attendanceLog.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="isOpen"
      @hiding="closeModal"
    >
      <template #content>
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: '닫기',
          width: '120',
          height: '40',
          onClick: () => {
            closeModal();
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxToolbarItem,
      DxPopup,
    },
    props: {
      isOpen: {
        default: false,
        type: Boolean,
      },
      scheduleId: {
        // 근무스케줄 ID
        default: -1,
        type: Number,
      },
    },
    data() {
      return {
        attendanceLog: {
          label: '출결정보 변경 이력',
          minWidth: 800,
          width: 800,
          minHeight: 600,
          height: 600,
        },
        dataGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'attendanceLogGrid', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '엑셀파일명', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: false, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: true, // 컬럼 사이즈 자동 조절 유무
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          // width:'200',   // 주석처리시 100%
          height: '460', // 주석처리시 100%
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: false, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'none', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'page', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'none', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: false, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '출결 상태',
              dataField: 'attendanceStatus',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              calculateCellValue: this.mergeAttendanceStatus,
            },
            {
              caption: '출결 시간',
              dataField: 'editWorkTime',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              calculateCellValue: this.mergeEditWorkTime,
            },
            {
              caption: '변경 사유',
              dataField: 'description',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '수정자',
              dataField: 'editAgent',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              calculateCellValue: this.mergeAgentInfo,
            },
            {
              caption: '변경 일시',
              dataField: 'editDt',
              format: 'yyyy-MM-dd HH:mm:ss',
              dataType: 'date',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
          ], // 컬럼 정보
        },
      };
    },
    watch: {
      isOpen: {
        handler: function (val) {
          if (val) {
            this.selectDataList();
          }
        },
        immediate: true,
      },
    },
    methods: {
      closeModal() {
        this.$emit('closeModal');
      },
      /**
       * 근태변경이력 그리드 데이터 호출
       * @return {Promise<*>}
       */
      async selectDataList() {
        const vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'attendanceWorkTimeEditId',
          async load() {
            const payload = {
              actionname: 'EWM_APPROVED_WORKTIME_EDIT_LIST',
              path: '/' + vm.scheduleId,
              useErrorPopup: true,
            };

            let rtnData = [];
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData = res.data.data;
            }
            return rtnData;
          },
        });
      },
      /**
       * 근태변경이력 그리드 수정자 정보 합치기
       * @param rowData
       * @return {string}
       */
      mergeAgentInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }

        if (rowData?.editNm === null) {
          return rowData?.editId;
        }

        return `${rowData?.editNm} [${rowData?.editId}]`;
      },
      /**
       * 근태변경이력 그리드 출결시간 합치기
       * @param rowData
       * @return {string}
       */
      mergeEditWorkTime(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData?.loginTimeEdit.substring(0, 2)}:${rowData?.loginTimeEdit.substring(2, 4)}
			  ~ ${rowData?.logoutTimeEdit.substring(0, 2)}:${rowData?.logoutTimeEdit.substring(2, 4)}`;
      },
      /**
       * 근태변경이력 그리드 출결상태 합치기
       * @param rowData
       * @return {string}
       */
      mergeAttendanceStatus(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }

        if (rowData?.normalEditFl === 'Y') {
          return '정상';
        }

        if (rowData?.tardinessEditFl === 'Y') {
          return '지각';
        }

        if (rowData?.absentEditFl === 'Y') {
          return '결근';
        }

        if (rowData?.dayoffEditFl === 'Y') {
          return '조퇴';
        }
      },
    },
    async mounted() {
      await this.selectDataList();
    },
  };
</script>

<style lang="scss" scoped></style>