var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('DxPopup',{attrs:{"show-title":true,"title":_vm.attendanceLog.label,"min-width":_vm.attendanceLog.minWidth,"width":_vm.attendanceLog.width,"min-height":_vm.attendanceLog.minHeight,"height":_vm.attendanceLog.height,"drag-enabled":true,"resize-enabled":true,"show-close-button":true,"close-on-outside-click":false,"visible":_vm.isOpen},on:{"hiding":_vm.closeModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('esp-dx-data-grid',{ref:_vm.dataGrid.refName,attrs:{"data-grid":_vm.dataGrid}})]},proxy:true}])},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
        elementAttr: {
          class: 'white filled txt_S medium',
        },
        text: '닫기',
        width: '120',
        height: '40',
        onClick: () => {
          _vm.closeModal();
        },
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }